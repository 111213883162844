import React from 'react'

import './StrategicAdvisory.css'
import { Col, Container, Row } from 'react-bootstrap'

export const StrategicAdvisory = () => {
    return (
        <div>
            <section className='Strategic-Advisory-Section1'>
              
                <Container className=''>
                    <Row style={{ alignItems: 'center' }}>
                        <Col lg={7}>
                            <div className='Strategic-Advisory-Section1-content'>
                                <h1 >Strategic Advisory</h1>
                                <p >A value proposition highlights the unique benefits a product or service offers,
                                explaining why it’s the best choice for addressing customer needs.</p>

                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="rightBannersection">
                                <img width='100%' src={require("../Assets/Strategic-Advisory/Strategic Advisory-banner-image.png")} alt='Ai-hero-img' loading='laxzy' />
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            <section>
                <Container>

                    <Row className='justify-content-center align-items-center m-3'>

                        <Col lg={4} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-content'>
                            <div className='empty-div'></div>
                                <h2>Data maturity Assessment</h2>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data As Is Analysis.png")} alt="AI Advisory-Icons-01" loading='lazy'/>
                                    <p>Data As Is Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data Maturity Assessment.png")} alt="AI Advisory-Icons-02" loading='lazy'/>
                                    <p>Data Maturity Assessment</p>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data Enrichment.png")} alt="AI Advisory-Icons-04" loading='lazy'/>
                                    <p>Data Enrichment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Road Map.png")} alt="AI Advisory-Icons-05" loading='lazy'/>
                                    <p>Road Map</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12}  >
                            <div className='Strategic-Advisory-Section2-image'>
                                <img className='w-100' src={require("../Assets/Strategic-Advisory/Data maturity Assessment.png")} alt="ldg-key-aspects" loading='lazy' />

                            </div>
                        </Col>
                    </Row>



                    <Row className='justify-content-center align-items-center m-3 mt-5 pt-5'>
                        <Col lg={7} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-image'>
                                <img className='w-100' src={require("../Assets/Strategic-Advisory/Process Maturity Assessment.png")} alt="ldg-key-aspects" loading='lazy'/>

                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-content'>
                            <div className='empty-div'></div>
                                <h2>Process Maturity Assessment</h2>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data As Is Analysis.png")} alt="AI Advisory-Icons-01" loading='lazy'/>
                                    <p>Data As Is Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data Maturity Assessment.png")} alt="AI Advisory-Icons-02" loading='lazy'/>
                                    <p>Data Maturity Assessment</p>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data Enrichment.png")} alt="AI Advisory-Icons-04" loading='lazy'/>
                                    <p>Data Enrichment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Road Map.png")} alt="AI Advisory-Icons-05" loading='lazy'/>
                                    <p>Road Map</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='justify-content-center align-items-center m-3  mt-5 pt-5'>

                        <Col lg={4} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-content'>
                            <div className='empty-div'></div>
                                <h2>AI Readiness Assessment</h2>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Artboard 1_6.png")} alt="AI Advisory-Icons-01.png" loading='lazy'/>
                                    <p>As Is Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Artboard 1_8.png")} alt="AI Advisory-Icons-02.png" loading='lazy'/>
                                    <p>Data Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data Maturity Assessment.png")} alt="AI Advisory-Icons-03.png" loading='lazy'/>
                                    <p>Data Maturity Assessment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Data Enrichment.png")} alt="AI Advisory-Icons-04" loading='lazy'/>
                                    <p>Data Enrichment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Road Map.png")} alt="AI Advisory-Icons-05" loading='lazy'/>
                                    <p>Road Map</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/Strategic Advisory icons/Recommended Architecture.png")} alt="AI Advisory-Icons-06" loading='lazy'/>
                                    <p>Recommended Architecture</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12}  >
                            <div className='Strategic-Advisory-Section2-image'>
                                <img className='w-100' src={require("../Assets/Strategic-Advisory/AI Advisory-compressed.jpg")} alt="AI Advisory-compressed" loading='lazy'/>

                            </div>
                        </Col>
                    </Row>

                </Container>

            </section>
            {/* <section>
                <Container>
                    <div>
                        <h3>
                        Why Choose iMirAI
                        </h3>
                        <p>
                        Choosing iMirAI means opting for a unique solution that revolutionizes your approach to data analytics. Here are key reasons to consider iMirAI:
                        </p>
                    </div>
                    <Row>
                        <Col lg={4} md={6} sm={12} xs={12}>
                        <div className='iMirAi-points'>
                            <h4>User-Friendly Interface</h4>
                            <p>Designed for ease of use, iMirAI makes data analysis accessible for everyone, regardless of their expertise level.</p>

                        </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                        <div className='iMirAi-points'>
                            <h4>Data Simplification</h4>
                            <p> We transform intricate data sets into clear, visual representations, enabling users to grasp insights quickly.</p>

                        </div>
                        </Col><Col lg={4} md={6} sm={12} xs={12}>
                        <div className='iMirAi-points'>
                            <h4>Real-Time Insights</h4>
                            <p>Our AI-driven analytics deliver real-time insights, allowing for timely and informed decision-making.</p>

                        </div>
                        </Col><Col lg={4} md={6} sm={12} xs={12}>
                        <div className='iMirAi-points'>
                            <h4>Enhanced Decision-Making</h4>
                            <p>With advanced analytics at your fingertips, iMirAI supports smarter and faster decisions that can propel your business forward.</p>

                        </div>
                        </Col><Col lg={4} md={6} sm={12} xs={12}>
                        <div className='iMirAi-points'>
                            <h4>Comprehensive Analytics</h4>
                            <p>iMirAI offers robust analytics capabilities that cater to a wide range of business needs, from operational to strategic.</p>

                        </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                        <div className='iMirAi-points'>
                            <h4>AI-Powered Automation</h4>
                            <p>Automate routine tasks and focus on what matters most—driving your business success.</p>

                        </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
        </div>
    )
}
