import styles from "./DataQuality.module.css";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import clsx from "clsx";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from 'react-bootstrap/Card';

const DataQualityExcellence = () => {
  return (
    <>
      <section
        className={`${styles["padding-top-bottom"]} ${styles["bg-hero"]}`}
      >
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <div>
                <h1 className={`${styles["header-color"]} fw-bolder`}>
                  Data Quality Fuels AI Excellence
                </h1>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div>
                <img
                  src={require("../Assets/data-excellence/Superior-Data-Quality-Fuels-Unprecedented-AI-Excellence-Image.png")}
                  alt=""
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={clsx([styles["padding-top-bottom"]])}>
        <Container>
          <div className="d-flex flex-column align-items-start">
            <div className="">
              <h1 className={styles["headers-border-border-line"]}>
                Did You Know?
              </h1>
            </div>
            <Row
              className={clsx(
                [styles["did-you-know-section"]],
                "text-start align-items-center"
              )}
            >
              <Col xs={12} md={6}>
                <p>
                  <b>Artificial Intelligence (AI)</b> is experiencing tremendous
                  growth and is projected to reach a market size of $190 billion
                  by 2025, with a CAGR (Compound Annual Growth Rate) of over 36%
                  from 2021 to 2025.
                </p>
                <p>However,</p>
                <p>
                  A staggering 85% of AI projects fail due to poor data quality
                  or insufficient relevant data.
                </p>
                <p>
                  This all depends on one key element: <b>DATA QUALITY</b>.
                </p>
                <p>
                  Without a solid data foundation, even the smartest AI can’t
                  deliver accurate results. So, make every data point count for
                  AI excellence!
                </p>
              </Col>
              <Col xs={12} md={6}>
                <img
                  src={require("../Assets/data-excellence/Did You Know.png")}
                  alt=""
                  className="w-100"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className={clsx([styles["padding-top-bottom"],styles['bg-color-section']], "text-start")}>
        <Container>
          <div className="d-flex flex-column align-items-start ">
            <div className="mb-3">
              <h1 className={styles["headers-border-border-line"]}>
                AI’s Adoption Across Industries
              </h1>
              <p>
                Various industries and businesses of all verticals are adopting
                AI at lightning speed.
              </p>
              <p>Here are the impressive growth statistics.</p>
            </div>
            <div>
              <Row className={styles["ai-adoption-grid"]}>
                <Col className="my-3 py-3 border-end border-2">
                  <div className="d-flex flex-column align-items-center my-2 py-2">
                    <div className="d-flex align-items-center">
                      <img
                        className="w-25"
                        src={require("../Assets/data-excellence/Technology Sector-Icon.png")}
                        alt=""
                      />
                      <h2>60%</h2>
                    </div>
                    <div>
                      <p>
                        Various industries and businesses of all verticals are
                        adopting AI at lightning speed.
                      </p>
                    </div>
                    
                  </div>
                  <div className="d-flex flex-column align-items-center my-2 py-2">
                    <div className="d-flex align-items-center">
                      <img
                        className="w-25"
                        src={require("../Assets/data-excellence/Retail Sectors-Icon.png")}
                        alt=""
                      />
                      <h2>35%</h2>
                    </div>
                    <div>
                      <p className="mt-3">
                        35% of Retail Sectors implementing AI to improve
                        customer experience, optimize inventory, and forecast
                        demand and trends.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="my-3 py-3 border-end border-2">
                  <div className="d-flex flex-column align-items-center my-2 py-2">
                    <div className="d-flex align-items-center">
                      <img
                        className="w-25"
                        src={require("../Assets/data-excellence/Medium Enterprises-Icon.png")}
                        alt=""
                      />
                      <h2>45%</h2>
                    </div>
                    <div>
                      <p className="mt-3">
                        45% of Small and Medium Enterprises (SMEs) implementing
                        AI tools for customer service, marketing, and supply
                        chain management.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center my-2 py-2">
                    <div className="d-flex align-items-center">
                      <img
                        className="w-25"
                        src={require("../Assets/data-excellence/Financial Institutions-Icon.png")}
                        alt=""
                      />
                      <h2>50%</h2>
                    </div>
                    <div>
                      <p className="mt-3">
                        More than 50% of Financial Institutions deployed AI for
                        personalized banking, risk assessment, and fraud
                        detection.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="my-3 py-3">
                  <div className="d-flex flex-column align-items-center my-2 py-2">
                    <div className="d-flex align-items-center">
                      <img
                        className="w-25"
                        src={require("../Assets/data-excellence/Technology Sector-Icon.png")}
                        alt=""
                      />
                      <h2>30%</h2>
                    </div>
                    <div>
                      <p className="mt-3">
                        30% of Manufacturing Industries utilizing AI for process
                        optimization, quality control, and predictive
                        maintenance.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center my-2 py-2">
                    <div className="d-flex align-items-center">
                      <img
                        className="w-25"
                        src={require("../Assets/data-excellence/Healthcare Industries_icon.png")}
                        alt=""
                      />
                      <h2>40%</h2>
                    </div>
                    <div>
                      <p className="mt-3">
                        More than 40% of Healthcare Industries incorporated AI
                        for personalized medicine, diagnostics, and drug
                        discovery.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className={clsx([styles["padding-top-bottom"]])}>
        <Container>
          <div>
            <Row>
              <Col>
                <div>
                  <img
                    className="w-100"
                    src={require("../Assets/data-excellence/The Critical Role of Quality Data in AI.png")}
                    alt=""
                  />
                </div>
              </Col>
              <Col className={clsx([styles["critical-role-text-section"]])}>
                <div>
                  <h1 className={clsx(styles["headers-border-border-line"],styles['critical-line-break'])}>
                    The Critical Role of Quality Data in AI
                  </h1>
                </div>
                <div>
                  <p>
                    AI relies heavily on data to function effectively. At its
                    core, AI algorithms are designed to recognize patterns, make
                    predictions, and make decisions based on the information
                    provided to them.
                  </p>
                  <p>
                    So, high-quality data is not just a technical requirement.
                    It’s the backbone of AI’s ability to deliver accuracy,
                    efficiency, and ethical performance.
                  </p>
                  <p>
                    Imagine AI as a student. Without good study materials
                    (data), it can’t learn or perform well. The more accurate
                    the data, the better the AI’s ability to predict, decide,
                    and innovate.
                  </p>
                  <p>
                    When these 6 key <b>data quality dimensions</b> align, they
                    create a seamless flow of high-quality data, enabling AI to
                    make reliable decisions and drive innovation.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className={clsx(styles["padding-top-bottom"],styles['bg-color-section'])}>
        <Container className="text-start">
          <div className="d-flex flex-column align-items-start">
            <div>
              <h1 className={styles["headers-border-border-line-ai-transform"]}>
                How AI Uses Data to Transform Your Business?
              </h1>
              <p>
                AI uses data as its fuel to learn, make decisions, and provide
                valuable insights. The better the quality of the data, the more
                accurate and reliable the AI's predictions and decisions will
                be.
              </p>
            </div>
            <div>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <div className="d-flex flex-column rounded bg-white my-2 px-2 py-4 shadow-sm">
                    <div>
                      <h6 className="fw-bold">Data Collection</h6>
                    </div>
                    <div>
                      <p>
                        AI systems collect data from various sources (such as
                        databases, cloud storage, APIs, sensors, websites, and
                        social media) that can be structured, unstructured, and
                        semi-structured.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <div className="d-flex flex-column rounded bg-white px-2 my-2 py-4 shadow-sm">
                    <div>
                      <h6 className="fw-bold">Data Preprocessing</h6>
                    </div>
                    <div>
                      <p>
                        Raw data contains duplicates, inconsistencies, or missing
                        values. Before feeding this raw data to an AI model, it
                        should be cleansed, harmonized, and transformed. This step
                        includes removing duplicates, correcting errors, and
                        handling missing values.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <div className="d-flex flex-column rounded bg-white my-2 px-2 py-4 shadow-sm">
                    <div>
                      <h6 className="fw-bold">Training</h6>
                    </div>
                    <div>
                      <p>
                        Once the data is cleansed, it is used to train AI models.
                        During training, the AI system analyzes the data and
                        learns to identify patterns, trends, and correlations.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4} >
                  <div className="d-flex flex-column rounded bg-white my-2 px-2 py-4 shadow-sm">
                    <div>
                      <h6 className="fw-bold">Model Development</h6>
                    </div>
                    <div>
                      <p>
                        The AI learns from historical data and builds a model that
                        can predict outcomes based on new data inputs. The model
                        is adjusted through repeated testing and learning,
                        enabling its ability to make accurate predictions and
                        decisions.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <div className="d-flex flex-column rounded bg-white my-2 px-2 py-4 shadow-sm">
                    <div>
                      <h6 className="fw-bold">Prediction & Decision Making</h6>
                    </div>
                    <div>
                      <p>
                        AI systems can predict customer behaviour, detect fraud,
                        or recommend products based on historical data.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <div className="d-flex flex-column rounded bg-white my-2 px-2 py-4 shadow-sm">
                    <div>
                      <h6 className="fw-bold">Continuous Learning</h6>
                    </div>
                    <div>
                      <p>
                        The AI model continues to learn and improve over time as
                        more data is collected and fed. This ongoing learning
                        allows the system to adapt to changes and become more
                        accurate in its predictions or decisions.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
            <img className="w-100" src={require("../Assets/data-excellence/How We Help You Achieve Superior Data Quality.png")} alt="" /></Col>
            <Col>
              <div className="text-start">
                <h1 className={styles["headers-border-border-line"]}>
                  How We Help You Achieve Superior Data Quality?
                </h1>
              </div>
              <div>
                <p>
                  We don t just manage data, we manage the future of your
                  business.
                </p>
              </div>
              <div className={styles["accordian-section"]}>
                <Accordion className="text-start">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h6>iDQM: Data Quality at its Best!</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      Don’t let poor data quality hold your business back.
                      Optimize data quality throughout its entire lifecycle with
                      our Intelligence Data Quality Management (iDQM) solution
                      which incorporates robust data governance frameworks that
                      ensure your data is accurate, consistent, up-to-date,
                      actionable, and ready to fuel your AI implementation with
                      confidence.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h6>LDG: The Backbone of AI Success</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      Lean Data Governance (LDG) ensures data quality by
                      enforcing robust governance principles at every stage. By
                      applying lean principles—simple, swift, and agile—LDG
                      keeps your data clean, compliant, and ready for AI. It
                      ensures data is accurate, complete, and accessible,
                      empowering AI models to deliver optimal performance.
                      Through continuous monitoring and data quality
                      improvement, LDG guarantees AI initiatives are based on
                      trustworthy, actionable data.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h6>Conversational AIs: AI Lens & iMirAI</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      AI Lens, the copilot of Lean Data Governance and iMirAI,
                      the copilot in Data Migration solutions, drive AI success
                      by making data more accessible, actionable, and aligned
                      with the demands of AI models. These tools ensure your
                      data is not only of the highest quality but also
                      continuously improving, providing the perfect foundation
                      for AI-driven innovation.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={clsx(styles["padding-top-bottom"],styles['bg-color-section'])}>
        <Container>
          <div>
            <Row>
              <Col>
                <div className="text-start">
                  <div className={styles["strategic-header"]}>
                    <h1 className={styles['headers-border-border-line']}>Strategic Advisory Services</h1>
                    <p>
                      We go beyond technology by offering specialized strategic
                      advisory services to enhance your data and AI
                      capabilities. Our services include:
                    </p>
                  </div>
                  <div
                    className={
                      (styles["strategic-bottom-section"], "d-flex flex-column")
                    }
                  >
                    <Row className="align-items-center">
                      <Col md={2}>
                        <img
                          src={require("../Assets/data-excellence/Data Maturity Assessment_Icon-01.png")}
                          alt=""
                          className="w-100"
                        />
                      </Col>
                      <Col md={10} className="my-3">
                        <h6 className="fw-bold">Data Maturity Assessment</h6>
                        <p>
                          We assess your <b>data management practices</b> to provide
                          actionable insights that improve data quality,
                          governance, and operational efficiency.
                        </p>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col md={2}>
                        <img
                          src={require("../Assets/data-excellence/AI Readiness Assessment-01.png")}
                          alt=""
                          className="w-100"
                        />
                      </Col>
                      <Col md={10} className="my-3">
                        <h6 className="fw-bold">AI Readiness Assessment</h6>
                        <p>
                          We evaluate your organizations infrastructure,
                          processes, and data to ensure they are prepared for AI
                          implementation.
                        </p>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col md={2}>
                        <img
                          src={require("../Assets/data-excellence/Process Maturity Assessment-01.png")}
                          alt=""
                          className="w-100"
                        />
                      </Col>
                      <Col md={10} className="my-3">
                        <h6 className="fw-bold">Process Maturity Assessment</h6>
                        <p>
                          We optimize your business processes to align with your
                          data and AI goals, ensuring smoother operations and
                          higher efficiency.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className={clsx(styles["padding-top-bottom"])}>
        <Container>
          <h1 className={styles['header-color']}>Ready to Power Up Your AI with Superior Data Quality?</h1>
          <p>
            Contact us to discover how our solutions can fuel your AI success
            and drive unmatched innovation.
          </p>
          <div className={styles['contact-us-btn']}>
            <Button>Contact Us</Button>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DataQualityExcellence;
