import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { ReadMoreButton } from "../ExtraComponents/ReadMoreButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { BookADemo } from "../ExtraComponents/BookADemo";
import { IconButton, Tooltip } from "@mui/material";

export const NavbarTop = () => {
  return (
    <div>
      <Navbar id="nav-bar" expand="lg" className="bg-body-tertiary two-navs" fixed="top">
        {/* <Container style={{justifyContent:'flex-end'}}>
        <Nav >
          <Link className='header-tags-top' to="">Contact Us</Link>
        </Nav>
       </Container> */}
        <Container fluid>
          <Link to="/">
            <img
              className="w-25"
              src={require("../Assets/Header/pilog-logo.png")}
              alt="pilog-logo"
              loading="lazy"
            />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-center">
            <Nav
              className="me my-5 my-lg-0 pilog-nav justify-content-center"
              // style={{ maxHeight: '100%' }}
              navbarScroll
            >
              {/* <Link className='header-tags' to="/">Home</Link> */}

              {/* <Link className='header-tags' to="lean-data-governance"> LDG</Link> */}

              {/* <NavDropdown className='header-tags2' title="AI Lens" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown> */}

              {/* <NavDropdown className='header-tags2' title="LDG" id="navbarScrollingDropdown">
              <NavDropdown.Item > <Link className='' style={{textDecoration:'none'}} to="lean-data-governance">Lean Data Governance</Link></NavDropdown.Item> 

               <NavDropdown.Item ><Link to="subscribe-now">Subscription</Link> </NavDropdown.Item>
               
              </NavDropdown> */}
              {/* <NavDropdown className='header-tags2' title="DQM" id="navbarScrollingDropdown">
                <NavDropdown.Item > <Link className='' style={{ textDecoration: 'none' }} to="iDQM">Data Quality Management</Link></NavDropdown.Item>

                <NavDropdown.Item ><Link to="subscribe-now">Subscription</Link> </NavDropdown.Item>

              </NavDropdown> */}

              {/* <NavDropdown className='header-tags2' title="Strategic Advisory" id="navbarScrollingDropdown">
              <NavDropdown.Item > <Link className='' style={{textDecoration:'none'}} to="#">AI Advisory</Link></NavDropdown.Item> 

               <NavDropdown.Item ><Link to="#">Data Advisory</Link> </NavDropdown.Item>
               
              </NavDropdown> */}
              {/* <NavDropdown className='header-tags2' title="Value Propositions" id="navbarScrollingDropdown">
              <NavDropdown.Item > <Link className='' style={{textDecoration:'none'}} to="#">Extended Supply Chain Optimization</Link></NavDropdown.Item> 

               <NavDropdown.Item ><Link to="#">Enduring Asset Lifecycle Management</Link> </NavDropdown.Item>
               <NavDropdown.Item ><Link to="#">Maturing Business Partner Lifecycle Management</Link> </NavDropdown.Item>
               <NavDropdown.Item ><Link to="#">Seamless Data Migration and Effective Application Consolidation</Link> </NavDropdown.Item>
               <NavDropdown.Item ><Link to="#">Digital Transformation and Automation</Link> </NavDropdown.Item>
               
              </NavDropdown> */}

              <div className="nav-item dropdown header-tags">
                <Link className="nav-link top-links">
                  {" "}
                  Innovative Products{" "}
                </Link>
                <div class="dropdown-menu border-light m-0">
                  <Link to="lean-data-governance" className="dropdown-item">
                    Lean Data Governance{" "}
                  </Link>
                  <Link to="iDQM" className="dropdown-item">
                    Intelligent Data Quality Management
                  </Link>
                  <Link to="ai-lens" className="dropdown-item">
                    AI Lens
                  </Link>
                </div>
              </div>
              {/* <div className="nav-item dropdown header-tags">
                <Link className="nav-link top-links" > Resources  </Link>
                <div class="dropdown-menu border-light m-0">
                  <Link to="lean-data-governance" className="dropdown-item">Events </Link>
                  <Link to="iDQM" className="dropdown-item">Blogs</Link>
                 
                </div>
              </div> */}
              {/* <div className="nav-item dropdown header-tags">
                <Link className="nav-link top-links" to='iDQM'> DQM  </Link>
                <div class="dropdown-menu border-2 m-0">
                  <Link to="iDQM" className="dropdown-item">Data Quality Management</Link>
                  <Link to="subscribe-now" className="dropdown-item">Subscription</Link>
                </div>
              </div>
             
              <Link className='header-tags' to="ai-lens"> AI Lens</Link> */}

              <Link className="header-tags" to="strategic-advisory">
                Strategic Advisory
              </Link>

              <Link className="header-tags" to="value-proposition">
                {" "}
                Value Propositions
              </Link>

              {/* <Link className='header-tags' to=""> About Us</Link> */}

              <Link className="header-tags" to="Contact">
                {" "}
                Contact Us
              </Link>
            </Nav>
            <div className="d-flex">
              {/* <div class="search">
              <input type="text" class="search__input" placeholder="Type your text" />
              <button class="search__button">
  
                <SearchOutlinedIcon/>
              </button>
            </div> */}
              <div className="mx-4">
                <Link to="https://calendly.com/customerdemos/ldg?month=2024-09">
                  <BookADemo name="Book a Demo" />
                </Link>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
