import React from "react";
import "./DataQalityManagement.css";
import {
  Carousel,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ListItem } from "@mui/material";
import {
  dataCataLogRules,
  dataQualityArray,
  sparesDataArray,
  taxonomyArr1,
  taxonomyArr2,
} from "../../constants/constants";

export const DataQalityManagement = () => {
  return (
    <div>
      <section className="idqm-updated">
        <div>
          <Row className="d-flex justify-content-center align-items-center text-start">
            <Col lg={7}>
              <div className="idqm-updated-heading">
                <h1>Intelligent Data Quality Management</h1>
                <p>
                  Preconfigured templates, Auto Assignment of Class &
                  Characteristics, <br />
                  ISO 8000 and UNSPSC compliant
                </p>
              </div>
              {/* <Link to='../subscribe-now'><ReadMoreButton name="Subscribe Now" /></Link> */}
              <button className="hero-button">Subscribe Now</button>
            </Col>
            <Col lg={5} className="d-flex justify-content-center">
              <img
                className="idqm-updated-banner-side"
                src={require("../Assets/iDQM/iDQM.png")}
                alt=""
                loading="lazy"
              />
            </Col>
          </Row>
        </div>
      </section>
      <section
        className="iDQM button-section py-5 idqm-buttons"
        data-name="buttons"
      >
        <div className="d-flex justify-content-around">
          <button className="border bg-white rounded px-2 py-2">
            PiLog Preferred Records (PPR)
          </button>
          <button className="border bg-white rounded px-2 py-2">
            PiLog Preferred Ontology (PPO)
          </button>
          <button className="border bg-white rounded px-2 py-2">
            PiLog Preferred Hierarchy (PPH)
          </button>
        </div>
      </section>
      <section className="iDQM-Section3">
        <Container>
          <div className="iDQM-Section3-heading">
            <h2>About Data Quality Management</h2>
            <p>
              Data Quality Management is aimed to automate the process of
              standardization, cleansing & management of unstructured / free
              text data by utilizing ASA (Auto Structured Algorithms) built on
              PiLogs taxonomy and the catalog repositories of master data
              records.
            </p>
          </div>
          <Row className="justify-content-center align-items-center py-2">
            <Col>
              <div className="iDQM-Section3-items">
                <h4 className="text-start fw-bolder">
                  Data Quality Management Capabilities includes but not limited
                  to
                </h4>
                <ul className="my-1 p-3">
                  {dataQualityArray.map((list, id) => (
                    <li key={id} className="text-start">
                      <p>{list}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-center">
                <div>
                  <img
                    className="w-100"
                    src={require("../Assets/iDQM/Data-Quality-Levels-with-samples1.png")}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="d-flex flex-column">
                  <img
                    className="w-100"
                    src={require("../Assets/iDQM/Data-Quality-Levels-with-samples2.png")}
                    alt=""
                    loading="lazy"
                  />
                  <img
                    className="w-100"
                    src={require("../Assets/iDQM/Data-Quality-Levels-with-samples3.png")}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="spares-data-acquisition py-5 section-bg-cls"
        data-section-name="Spares Data Acquisition"
      >
        <Container className="">
          <div className="d-flex flex-column align-items-start w-100">
            <div className="spares-header">
              <h1 className="fw-bolder">Spares Data Acquisition</h1>
            </div>
            <div className="spares-body w-100">
              <p className="text-end fw-bold">DH Algorithms iMDRM V10</p>
              <div>
                <img
                  className="w-100"
                  src={require("../Assets/iDQM/Spares Data Acquisition-1.png")}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div>
                <Row>
                  <Col>
                    <ul>
                      {sparesDataArray.map((list, id) => (
                        <li key={id} className="text-start">
                          <p>{list}</p>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col>
                    <img
                      className="w-100"
                      src={require("../Assets/iDQM/Spares Data Acquisition-2.png")}
                      alt=""
                      loading="lazy"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        className="Data-Quality-Standards py-5"
        data-section-name="Data Quality Standards"
      >
        <Container className="">
          <div className="d-flex flex-column align-items-start w-100">
            <div className="spares-header">
              <h1 className="fw-bolder">Data Quality Standards</h1>
            </div>
            <div className="data-quality-body w-100">
              <div>
                <Row>
                  <Col className="align-self-center">
                    <img
                      className="w-100"
                      src={require("../Assets/iDQM/Data Cleansing-05.png")}
                      alt="Data Cleansing-05"
                      loading="lazy"
                    />
                  </Col>
                  <Col className="text-start">
                    <p>
                      The International Organization for Standardization (ISO)
                      approved a set of standards for data quality as it relates
                      to the exchange of master data between organizations and
                      systems. These are primarily defined in the ISO 8000-110,
                      120, 130, 140 and the ISO 22745-10, 30 and 40 standards.
                      Although these standards were originally inspired by the
                      business of replacement parts cataloguing, the standards
                      potentially have a much broader application. The ISO 8000
                      standards are high level requirements that do not
                      prescribe any specific syntax or semantics. On the other
                      hand, the ISO 22745 standards are for a specific
                      implementation of the ISO 8000 standards in extensible
                      markup language (XML) and are aimed primarily at parts
                      cataloguing and industrial suppliers
                    </p>
                    <p className="my-3">
                      PiLog Data Harmonization processes & methodologies
                      complies to ISO 8000 & ISO 22745 standards
                    </p>
                    <p>
                      PiLog utilizes the PiLog Preferred Ontology (PPO) when
                      structuring and cleansing Material, Asset/Equipment &
                      Services Master records ensuring data deliverables comply
                      with the ISO 8000 methodology, processes & standards for
                      Syntax, Semantics, Accuracy, Provenance and Completeness
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section
        className="taxonomy-section  section-bg-cls"
        data-section-name="Taxonomy"
      >
        <Container>
          <div className="d-flex flex-column align-items-start w-100">
            <div>
              <h1 className="fw-bolder">Taxonomy</h1>
            </div>
            <div className="">
              <Row>
                <Col lg={6} md={6} xl={8} xxl={8}>
                  <div className="tax-para-main text-start">
                    <div className="">
                      <p>
                        PiLog throughout the 25 years of experience in master
                        data solutions across different industries have
                        developed PiLog Preferred Ontology (PPO) which is a
                        Technical Dictionary that complies with the ISO 8000
                        standard. The PPO is a best-defined industry specific
                        dictionary covering all industry verticals such as
                        Petrochemical, Iron & Steel, Oil & Gas, Cement,
                        Transport, Utilities, Retail etc.
                      </p>
                      <p className="my-2">
                        PiLog s Taxonomy consists of pre-defined templates. Each
                        template consists of a list of classes (object qualifier
                        or noun-modifier combination) with a set of predefined
                        characteristics (properties / attributes) per class.
                        PiLog will make the PPO
                        (class/characteristics/abbreviations) available for
                        general reference via the Data Harmonization Solution
                        (DHS) and Master Data Ontology Manager (MDOM) tools
                      </p>
                    </div>
                    <div className="my-4">
                      <h6 className="fw-bolder">
                        PiLog creates client preferred ontology (CPO) by copying
                        general templates common to most companies/industries,
                        as well as known, expected templates for the specific
                        client. The Client team will confirm the CPO templates
                        by approving:
                      </h6>
                      <ul>
                        {taxonomyArr1.map((list, id) => (
                          <li key={id} className="text-start">
                            <p>{list}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg={5} md={6} xl={4} xxl={4}>
                  <img
                    className="w-100"
                    src={require("../Assets/iDQM/Spares Data Acquisition-07.png")}
                    alt="spares-data"
                    loading="lazy"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <img
                    className="w-100"
                    src={require("../Assets/iDQM/Spares Data Acquisition-06.png")}
                    alt=""
                  />
                </Col>
                <Col>
                  <h6>
                    PiLog reserves the right to make these changes in the
                    dictionary:
                  </h6>
                  <ul>
                    {taxonomyArr2.map((list, id) => (
                      <li key={id} className="text-start">
                        <p>{list}</p>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>

      <section className="iDQM-Section7" data-section-name="Data Cleansing">
        <Container>
          <div className="d-flex flex-column align-items-start">
            <div>
              <h1 className="fw-bolder">Data Cleansing</h1>
            </div>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="iDQM-Section7-heading">
                  <div className="iDQM-Section7-items">
                    <p>
                      The PiLog Master Data Project Management is used for
                      cleansing and structuring of a material master is a highly
                      specialized field, requiring the use of international
                      standards, such as eOTD, USC, EAN, ISO 8000 etc.
                    </p>
                    <p>
                      Effective cleansing and structuring of a material master
                      consistent and correct application of these standards in
                      large volumes of data requires specialized processes,
                      methodologies and software tools.
                    </p>
                    <p>
                      The material master forms the basis for a myriad of
                      business objectives. PiLog understands the complex task of
                      translating selected business objectives into master data
                      requirements and subsequently designing a project that is
                      focused on delivering optimal results in a cost effective
                      way
                    </p>
                    <p>
                      For a large number of line items effective cleaning of the
                      material master does require the cleaning and
                      standardization of the manufacturers and/or suppliers. It
                      therefore follows that a vendor/supplier cleanup and
                      standardization is a logical consequence in the process
                    </p>
                    <div className="mt-4">
                      <p>
                        PiLog has its own specialized data refinery, PiLog Data.
                        PiLog has developed superior technology and
                        methodologies that are aimed at delivering the best
                        possible quality, consistently and cost effectively.
                      </p>
                      <p>
                        In answering the market need for the cataloguing of
                        services in a consistent and repetitive manner, PiLog
                        developed the world s first internationally proven
                        standard for services cataloguing, the USC. Although
                        this has now been accepted to be part of the eOTD, the
                        specific methodologies required to implement it
                        successfully remains with PiLog
                      </p>
                      <p>
                        The material master, as well as other master data
                        tables, requires standardized base tables for, amongst
                        others; unit of measure, unit of purchase, material
                        types and material groups. This is also a specialty of
                        PiLog
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <img
                  src={require("../Assets/iDQM/Data Cleansing Strategy - Multi Translations-08.png")}
                  alt="Data Cleansing-09"
                  loading="lazy"
                  className="w-100"
                />
                <img
                  style={{ width: "100%" }}
                  src={require("../Assets/iDQM/Data Cleansing-09.png")}
                  alt="Data Cleansing-09"
                  loading="lazy"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section
        className="data-catalog section-bg-cls"
        data-section-name="Data Catalog"
      >
        <Container>
          <div className="d-flex ali-items-center flex-column">
            <div>
              <h1 className="fw-bolder">Data Cataloging</h1>
              <p>Data cataloging is classified below methods:</p>
            </div>
            <div className="pt-5">
              <Row>
                <Col lg={4} md={8} sm={12}>
                  <div className="our-products-content border rounded-2 p-2">
                    <img
                      className="w-100 rounded-3"
                      src={require("../Assets/Home/lean-data-governance.jpg")}
                      alt=""
                    />
                    <h6 className="my-2">
                      Basic Structuring (Reference data extraction & Allocation
                      of a PPO-OTD class)
                    </h6>
                    <ul>
                      <li style={{ fontSize: "14px" }}>
                        Reference values, such as manufacturer name and part
                        number; drawing number, supplier references, and any
                        other reference values of the item, are identified and
                        captured. These are used to describe the item during
                        purchasing
                      </li>
                      <li style={{ fontSize: "14px" }}>
                        Each item is assigned to a PPO class. This allows all
                        items to be grouped, as defined by the dictionary, and
                        to have corresponding templates assigned to items.
                      </li>
                    </ul>

                    <div className="service-button"></div>
                  </div>
                </Col>
                <Col lg={4} md={8} sm={12}>
                  <div className="our-products-content border rounded-2 p-2">
                    <img
                      className="w-100 rounded-3"
                      src={require("../Assets/Home/idqm-short.jpg")}
                      alt=""
                    />
                    <h6 className="my-2">
                      Advanced Structuring (Value extraction)
                    </h6>
                    <p className="text-left">
                      Data Quality Management is aimed to automate the process
                      of standardization, cleansing & management of
                      unstructured/free text data by utilizing ASA (Auto
                      Structured Algorithms) built on PiLog’s taxonomy and the
                      catalog repositories of master data records.
                    </p>

                    <ul>
                      <li style={{ fontSize: "14px" }}>
                        Allocation of PPO OTD properties (template),
                        Attribute/Property values, UOM's extraction &
                        population, Cleaning & structuring of free text, if any
                      </li>
                    </ul>
                    <div className="service2-button"></div>
                  </div>
                </Col>
                <Col lg={4} md={8} sm={12}>
                  <div className="our-products-content border rounded-2 p-2">
                    <img
                      className="w-100 rounded-3"
                      src={require("../Assets/Home/AI-Lense.png")}
                      alt="AI-Lens"
                    />
                    <h6 className="my-2">Enrichment</h6>
                    <ul>
                      <li style={{ fontSize: "14px" }}>
                        Data enrichment is performed with the help of genuine
                        reference data that is extracted from the source data by
                        means of external research to generate additional data
                        from technical sources, such as manufacturer s catalogs
                        (PDF library) or the Internet (manufacturer s website,
                        catalog cuts, etc.), as well as cross-verification and
                        cross-validation of extracted data that is captured in
                        Advanced Structuring. Further cleansing occurs through
                        validation, cross reference, and harmonization.
                      </li>
                    </ul>

                    <div className="service3-button"></div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section className="catalog-rules py-4 pt-5" data-section-name>
        <Container>
          <div className="d-flex flex-column">
            <div>
              <h1 className="fw-bolder">Cataloging Rules</h1>
              <p className="catalog-small-text">PiLog follows three cataloging rules as follows:</p>
            </div>
            <div>
              <ul>
                {dataCataLogRules.map((list, id) => (
                  <li key={id} className="text-start">
                    <p>{list}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </section>
      <section className="iDQM-Section9" data-section-name>
        <Container>
          <div className="d-flex flex-column align-items-start">
            <div className="">
              <h1>Data Quality Levels with samples:</h1>
            </div>
            <Carousel data-bs-theme="dark">
              <Carousel.Item interval={1000}>
                <img
                  className="w-100"
                  src={require("../Assets/iDQM/Data-Quality-Levels-with-samples1.png")}
                  alt="Data-Quality-Levels-with-samples1"
                  loading="lazy"
                />
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <img
                  className="w-100"
                  src={require("../Assets/iDQM/Data-Quality-Levels-with-samples2.png")}
                  alt="Data-Quality-Levels-with-samples2"
                  loading="lazy"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="w-100"
                  src={require("../Assets/iDQM/Data-Quality-Levels-with-samples3.png")}
                  alt="Data-Quality-Levels-with-samples3"
                  loading="lazy"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={5}>
              <div className="iDQM-Section10-heading">
                <h1 className="fw-bolder">Add-Ons</h1>
                <h6>
                  Explore our brief videos showcasing the features of our unique
                  add-ons of data quality management for your reference. Click
                  here to watch.
                </h6>
                <div className="iDQM-Section10-items">
                  <p>
                    {" "}
                    <ArrowRightIcon style={{ width: "3rem", height: "3rem" }} />
                    Smart Consolidation
                  </p>
                  <p>
                    <ArrowRightIcon style={{ width: "3rem", height: "3rem" }} />
                    RPA Robotic Process Automation BOTs
                  </p>
                  <p>
                    <ArrowRightIcon style={{ width: "3rem", height: "3rem" }} />
                    Data Profiling and Analysis
                  </p>
                  <p>
                    <ArrowRightIcon style={{ width: "3rem", height: "3rem" }} />
                    iSPIR
                  </p>
                </div>
              </div>
            </Col>
            <Col>
              <img
                className="w-100"
                src={require("../Assets/iDQM/Add-Ons.jpg")}
                alt="Add ons"
                loading="lazy"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
