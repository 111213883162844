import React, { useEffect, useState } from 'react'
import { Button, Carousel, Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import './Home.css'
import { ReadMoreButton } from '../ExtraComponents/ReadMoreButton'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PublicIcon from '@mui/icons-material/Public';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { BookADemo } from '../ExtraComponents/BookADemo';
import piLogGartner from '../Assets/Home/PiLog-gartner.png'; // Default image
import image1 from '../Assets/Home/magic-quadrant.png'; // Example additional images
import image2 from '../Assets/Home/vendors-sorted.jpg';
import image3 from '../Assets/Home/data-quadrant-master-data.png';
import image4 from '../Assets/Home/Master_Data_Management_-_EF.png';
import image5 from '../Assets/Home/MDM-Data-Quadrant.jpg';
import image6 from '../Assets/Home/Data_Quality-EFD.png';
import { HeroCarouselSection } from '../HeroCarouselSection/HeroCarouselSection';
import styled from '@emotion/styled/macro';
import TabComponent from '../TabComponent/TabComponent';
import { Link } from 'react-router-dom';
import { GartnerComponent } from '../ExtraComponents/GartnerComponent';




const AccordionItem = ({ title, content, isActive, onClick }) => (
    <div className={`accordion-item ${isActive ? 'active' : ''}`} onClick={onClick}>
        <div className="accordion-title">
            {title}
            <span className="accordion-icon">{isActive ? <ArrowUpwardOutlinedIcon /> : <ArrowForwardOutlinedIcon />}</span>
        </div>
        {isActive && (
            <div className="accordion-content">
                <p>{content}</p>
            </div>
        )}
    </div>
);

export const Home = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [slider, setSlider] = useState(null);
    const [currentImage, setCurrentImage] = useState(piLogGartner);
    const [selectedTab, setSelectedTab] = useState('Magic Quadrant'); // State to track selected tab

    const items = [
        {
            title: "Gartner Recognition",
            content: "PiLog's acclaim from Gartner highlights its commitment to delivering effective Master Data Management solutions, endorsed by customer feedback and industry evaluation.",
            tabs: [
                { name: 'Magic Quadrant', image: image1 },
                { name: 'Voice of Customer', image: piLogGartner },
                { name: 'Vendor Spectrum', image: image2 }
            ]
        },
        {
            title: 'Infotech - Software Reviews Recognition (iMDRM)',
            content: 'PiLog MDRM achieved Recognition as the Emotional Footprint Champion and Data Quadrant Gold Medalist in the Master Data Management category from Infotech Software Reviews.',
            tabs: [
                { name: 'Data Quadrant', image: image3 },
                { name: 'Emotional Footprint', image: image4 }
            ]
        },
        {
            title: 'Infotech - Software Reviews Recognition (iDQM)',
            content: 'PiLog iDQM achieved Recognition as the Emotional Footprint Champion and Data Quadrant Gold Medalist in the Data Quality category from Infotech Software Reviews.',
            tabs: [
                { name: 'Data Quadrant', image: image5 },
                { name: 'Emotional Footprint', image: image6 }
            ]
        },
    ];

    const AccordionItem = ({ title, content, isActive, onClick }) => (
        <div className={`accordion-item ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className="accordion-title">
                {title}
                <span className="accordion-icon">{isActive ? <ArrowUpwardOutlinedIcon /> : <ArrowForwardOutlinedIcon />}</span>
            </div>
            {isActive && (
                <div className="accordion-content">
                    <p className='text-start'>{content}</p>
                </div>
            )}
        </div>
    );

    const handleClick = (index) => {
        setActiveIndex(index);
        setCurrentImage(items[index].tabs[0].image);
        setSelectedTab(items[index].tabs[0].name);
    };

    const handleTabSelect = (key, item) => {
        setSelectedTab(key);
        const selectedTab = item.tabs.find(tab => tab.name === key);
        setCurrentImage(selectedTab.image);
    };
    // carousel functionality
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const textItems = [
        'We see the DATA as the PULSE of any organization. So, our primary goal was to transform data management. Since our inception, we set out to innovate solutions that empower businesses to harness the full potential of data.',
        'Key Considerations for Selecting the Right Master Data Management Solutions ProviderKey Considerations for Selecting the Right Master Data Management Solutions ProviderKey Considerations for Selecting the Right Master Data Management',
        'Data Management Key Considerations for Selecting the Right Master Data Management Solutions ProviderKey Considerations for Selecting the Right Master Data Management Solutions ProviderKey Considerations ',
    ];

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://www.gartner.com/peer-insights/vendor-portal/public/Widget/js/widget.js';
        script1.async = true;
        document.body.appendChild(script1);

        script1.onload = () => {
            const script2 = document.createElement('script');
            script2.innerHTML = `
                if (document.querySelector("#widget-container")) {
                    GartnerPI_Widget({
                        size: "large",
                        theme: "dark",
                        sourcingLink: "",
                        widget_id: "NGQxM2ExZWUtZWFiMy00ZjUzLWI1MjEtZDlhMTBkYmM1YTBh",
                        version: "2",
                        container: document.querySelector("#widget-container")
                    });
                }
            `;
            document.body.appendChild(script2);
        };

        return () => {
            document.body.removeChild(script1);
            // Ensure to remove the second script as well
            const script2 = document.querySelector('script[innerHTML*="GartnerPI_Widget"]');
            if (script2) {
                document.body.removeChild(script2);
            }
        };
    }, []);

    return (
        <div>
            <section className='bg-light'>
                {/* <img className='w-100' src={require("../Assets/Home/Untitled-7.jpg")} alt="" /> */}
            </section>
            {/* <section className='py-5 bg-light'>
                <Container>
                    <Row>
                        <Col lg={5}>
                        <div>
                            <img className='w-100' src={require("../Assets/Home/Optimized-Supply-Chain.png")} alt="Optimized Supply Chain" />
                        </div>
                        </Col>
                        <Col lg={7}>
                        <div className='optimize-content'>
                                <h2>Optimized Supply Chain of Petrochemical Conglomerate</h2>
                                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>

                                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                                <div className='d-flex text-left mt-5'>
                                    <ReadMoreButton name="Read Now" />

                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
<section className='new-home-sec'>
    <Container>
        <Row >
            <Col lg={6} className='d-flex justify-content-center align-items-center'>
            <div className='my-3'>
                <h1 className='Hero-heading text-start'>Data Quality Management Powered by Data Science</h1>
                <p className='text-start h4'>Transforming data quality management with the power of data science.
                Drive accuracy, consistency, and trust in every decision.</p>
                <button className='hero-button'>Read More</button>
                {/* <Carousel>
                    <Carousel.Item className='text-start'>
                        <h5 className='text-start'>
                        Lean Data Governance
                        </h5>
                        <p >The solution emphasizes the 'less is more' philosophy by implementing consistent data quality and governance processes, instead of complex, scaled-up procedures.The primary objective is to provide unified data across the enterprise, ensuring accuracy, timeliness, completeness, consistency, integrity, and compliance etc.</p>
                    </Carousel.Item>
                    <Carousel.Item className='text-start'>
                        <h5>
                        Data Quality Management
                        </h5>
                        <p>
                        Data Quality Management is aimed to automate the process of standardization, cleansing & management of unstructured/free text data by utilizing ASA (Auto Structured Algorithms) built on PiLog’s taxonomy and the catalog repositories of master data records.
                        </p>
                    </Carousel.Item>
                    <Carousel.Item className='text-start'>
                        <h5>
                        AI Lens
                        </h5>
                       
                        <p>
                        The Conversational AI is application of Artificial Intelligence that simulates human like conversations engaged in answering queries, guides the users as subject matter expert, provides insights on specific data points, showcases the analytics, pivoting the data,transverses the processes as per the personalized configuration
                        </p>
                        </Carousel.Item>
                </Carousel> */}
            </div>
            </Col>
            <Col lg={6} className='d-flex justify-content-start align-items-center'>
                    <img className='' style={{width:'120%'}} src={require("../Assets/Home/Data-science-driven-data-quality-management-Image.png")} alt="" />
            </Col>
            
        </Row>
    </Container>
</section>

            <section className='our-products'>

                <Container >
                    <Row className='d-flex '>
                        <h2 className='our-products-heading'>Our Products</h2>
                        <Col lg={4} md={8} sm={12}>
                            <div className='our-products-content border rounded-2 p-2'>

                                <img className='w-100 rounded-3' src={require("../Assets/Home/lean-data-governance.jpg")} alt="" />
                                <h3 className='my-2'>Lean Data Governance (iMDRM)</h3>
                                <p className='text-left'>
                                    Lean Data Governance is the world’s first subscription-based, zero maintenance cloud-based product that can manage essential data sets, including materials, vendors, services, customers, & assets.

                                </p>
                                <ul>
                                    <li style={{ fontSize: '14px' }}>Helps businesses establish effective governance practices, streamline Master data governance on the cloud & gain greater visibility and control over their critical business information.
                                    </li>
                                    <li style={{ fontSize: '14px' }}>ERP agnostic/heterogenous</li>
                                </ul>

                                <div className='service-button'>
                                    <Link to='lean-data-governance'><ReadMoreButton name="Explore Now" /></Link>

                                </div>

                            </div>

                        </Col>
                        <Col lg={4} md={8} sm={12}>
                            <div className='our-products-content border rounded-2 p-2'>

                                <img className='w-100 rounded-3' src={require("../Assets/Home/idqm-short.jpg")} alt="" />
                                <h3 className='my-2'>Data Quality Management (iDQM)</h3>
                                <p className='text-left'>
                                    Data Quality Management is aimed to automate the process of standardization, cleansing & management of unstructured/free text data by utilizing ASA (Auto Structured Algorithms) built on PiLog’s taxonomy and the catalog repositories of master data records.
                                </p>

                                <ul>
                                    <li style={{ fontSize: '14px' }}>Analyze the source data content for completeness, consistency, redundancy, standardization, richness, etc
                                    </li>
                                    <li style={{ fontSize: '14px' }}>Auto Assignment of Class & Characteristics from the PiLog's Taxonomy to each record</li>
                                </ul>
                                <div className='service2-button'>
                                    <Link to='iDQM'><ReadMoreButton name="Explore Now" /></Link>
                                </div>
                            </div>

                        </Col>
                        <Col lg={4} md={8} sm={12}>

                            <div className='our-products-content border rounded-2 p-2'>

                                <img className='w-100 rounded-3' src={require("../Assets/Home/AI-Lense.png")} alt="AI-Lens" />
                                <h3 className='my-2'>AI Lens (Conversational AI)</h3>
                                <p className='text-left my-4'>PiLog AI Lens centralizes all your critical data, from materials and services to vendors, customers, assets, employees, and business partners. No more siloed information, no more manual processes.
                                    It is a diversified and automated data management system that provides liberty from complex operations and enhances time management.</p>

                                <div className='service3-button'>
                                    <Link to='ai-lens'><ReadMoreButton name="Explore Now" /></Link>
                                </div>
                            </div>

                        </Col>

                    </Row>
                </Container>

            </section>

            

            <section className='bg-light'>
                <Container>
                    <Row>
                        <TabComponent />
                    </Row>
                </Container>
            </section>

            <section className='client-reviews'>
                <Container>
                    <Row className="d-flex align-items-center justify-content-center text-start">
                        <Col lg={4} md={8} sm={12}>
                            <div >
                                <div className='client-review-content'>
                                    <h4>Client Testimonials</h4>

                                    <h2>Happy Client Says About Our Company</h2>
                                    <p>We see the DATA as the PULSE of any organization. So, our primary goal was to transform data management. Since our inception, we set out to innovate solutions that empower businesses to harness the full potential of data.</p>
                                </div>

                                <hr />
                                {/* <div className='d-flex gap-5 mt-3'>
                                    <div className='client-name'>
                                        <h6>Client Name</h6>
                                        <p>Master Data Management Solutions</p>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='rating-starts'>
                                            <StarOutlineOutlinedIcon />
                                            <StarOutlineOutlinedIcon />
                                            <StarOutlineOutlinedIcon />
                                            <StarOutlineOutlinedIcon />
                                            <StarOutlineOutlinedIcon />
                                        </div>
                                        <div className='rating p-1'>
                                            4.7
                                        </div>
                                    </div>
                                </div>
                                <div className='client-review-text'>
                                    <p className='text-left'>Big advise hero not list but the were thousand info best comma devious that blinds little an listen the awesome cleaning services.</p>
                                </div> */}
                            </div>
                        </Col>
                        <Col lg={4} md={10} sm={12}>
                            <div>
                                <img className='client-review-image' src={require("../Assets/Home/client-testimonials-Image.png")} alt="client-testimonials-Image" />
                            </div>
                        </Col>
                        <Col lg={4} md={10} sm={12} className='d-flex justify-content-center align-items-center'>
                            <div >
                                <div id="widget-container" style={{ width: '100%', height: '500px', overflowY: 'hidden', textAlign: 'center' }}></div>

                            </div>

                            {/* <div>
                            <GartnerComponent/>
                        </div> */}


                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='recognizations'>
                <Container>
                    <Row className=''>
                        <Col lg={6} md={8} sm={12}>
                            <div>
                                <div className='recognizations-heading'>
                                    <h2>
                                        PiLog has been recognized and mentioned in prominent advisories
                                        and research organizations
                                    </h2>
                                </div>

                                <div className="accordion">
                                    {items.map((item, index) => (
                                        <div key={index}>
                                            <AccordionItem
                                                title={item.title}
                                                content={item.content}
                                                button={item.button}
                                                isActive={index === activeIndex}
                                                onClick={() => handleClick(index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={8} sm={12}>
                            <div className='recognization-image'>
                                {items.map((item, index) => (
                                    index === activeIndex && (
                                        <Tabs
                                            key={index}
                                            activeKey={selectedTab}
                                            id={`uncontrolled-tab-example-${index}`}
                                            className="mb-3 recognize-tab"
                                            onSelect={(key) => handleTabSelect(key, item)}
                                        >
                                            {item.tabs.map((tab, tabIndex) => (
                                                <Tab className='recognize-tab-buttons' eventKey={tab.name} title={tab.name} key={tabIndex}>
                                                    <img className='' src={tab.image} alt={tab.name} />
                                                </Tab>
                                            ))}
                                        </Tabs>
                                    )
                                ))}

                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* <Container>
                    <Row>
                        <Col lg={6} md={8} sm={12}>

                        </Col>
                        <Col lg={6} md={8} sm={12}>
                        </Col>
                    </Row>

                </Container> */}
            </section>


            <section className='our-achivements'>
                <Container className='py-3'>

                    <Row className='justify-content-center align-items-center'>
                        <Col lg={4} md={7} xs={12} sm={12}>
                            <Row className='ach-awards'>
                                <Col lg={7} md={6} sm={8} xs={8}>
                                    <img className='ach-img' src={require("../Assets/Home/image_2024_06_04T04_45_22_372Z.png")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>“Voice of the Customer” Master Data Solutions Customers Choice Award 2024</p>
                                </Col>

                            </Row>
                            <Row>
                                <Col  >
                                    <img className='ach-img' src={require("../Assets/Home/software-reviews-gold-medal-mdm.png")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>PiLog's MDRM Solution Named 2024 Gold Medalist in Data Quadrant by SoftwareReviews</p>

                                </Col>
                                <Col >

                                    <img className='ach-img' src={require("../Assets/Home/software-reviews-gold-medal-dq.png")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>PiLog's Data Quality Named 2024 Gold Medalist in Data Quadrant by SoftwareReviews</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={7} xs={12} sm={12}>
                            <Row className=' justify-content-center align-items-center'>
                                <Col lg={11} className='achivements-content text-center' >
                                    <h2 className='text-center'>Our Achievements</h2>

                                </Col>
                                <Col lg={7} className=' justify-content-center align-items-center' >
                                    <img className='w-75' src={require("../Assets/Home/best-idea-award.jpg")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>Best Idea Award
                                        from our Global
                                        Business customer
                                        Vedanta</p>

                                </Col>
                            </Row>

                        </Col>
                        <Col lg={4} md={7} xs={12} sm={12}>
                            <Row className='ach-awards'>
                                <Col lg={7}>
                                    <img className='ach-img' src={require("../Assets/Home/best-performence-award.png")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>Best performing companies 2020</p>
                                </Col>

                            </Row>
                            <Row>
                                <Col >
                                    <img className='ach-img' src={require("../Assets/Home/software-reviews-champion-dq.png")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>PiLog's Data Quality Named 2024 Gold Medalist in Data Quadrant by SoftwareReviews</p>
                                </Col>
                                <Col >

                                    <img className='ach-img' src={require("../Assets/Home/software-reviews-champion-mdm.png")} alt="gartner-peer-insights-customer-first" />
                                    <p className='ach-img-content'>PiLog's Data Quality Named 2024 Gold Medalist in Data Quadrant by SoftwareReviews</p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
           


            {/* <section className="py-5 bg-light About" >
                <Container>
                    <Row className="d-flex align-items-center justify-content-start text-start">
                        <Col lg={6} >
                            <div className="about-us">
                                <h5>
                                    About Us
                                </h5>
                                <h2>
                                    We Excel in Digital Transformation & Master Data Management
                                </h2>

                                <p>
                                    Established in 1996, <strong>PiLog Group</strong> is a global group of independent companies, specializing in Quality Data and Digital Governance and Analytical solutions supporting multiple data domains in a variety of industries all over the globe. We have many resources operating globally including Africa's, America's, Europe, Middle East, Asia, etc. The PiLog's solutions are state of the art, focused on creating a common business language and managing the rules for the creation of high quality, multilingual terminology using Machine Learning, Artificial Intelligence technologies, and human-augmented algorithms for our customers who are eager to transform their businesses digitally.
                                </p>
                                <div className='my-4 justify-content-start align-items-center text-start'>
                                  

                                </div>
                                <Row>
                                    <Col lg={6} className="loyal-business  ">
                                        <div className="about-bottom">
                                            <GroupsOutlinedIcon className="group-icon" />
                                            <h6>Over 100+ Active Business Clients</h6>

                                        </div>
                                        <p className="bottom-content">
                                            Our loyal business clients & enduring relationships
                                            with them reflect their on going trust & satisfaction
                                            with our services.
                                        </p>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="about-bottom">
                                            <PublicIcon className="group-icon" />
                                            <h6 className='px-2'>Spanned across the globe</h6>
                                        </div>
                                        <p className="bottom-content px-2">
                                            With a global footprint, our services expand across
                                            countries, connecting with diverse markets worldwide
                                            & driving growth & innovation in Digital transformation.
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col lg={6} className="py-3" >

                            <div className="about-border-bottom">
                                <img src={require('../Assets/Home/About-us.png')} className=" about-us2-image" alt="about-us-image" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section className='product-carousel'>
                <Container>
                    <Row>
                        <div className="carousel-container">
                            <Col lg={8}>
                                <Slider ref={(c) => setSlider(c)} {...settings}>
                                    {textItems.map((item, index) => (
                                        <div key={index} className="carousel-slide">
                                            <h5>OUR BELIEFS</h5>
                                            <p>{item}</p>
                                            <div className='empty-div'></div>
                                        </div>
                                    ))}
                                </Slider>
                            </Col>
                            <Col>
                                <div className="carousel-buttons">
                                    <button className='pre-btn' onClick={() => slider.slickPrev()}>Previous <ArrowForwardOutlinedIcon /></button>
                                    <button className='next-btn' onClick={() => slider.slickNext()}>Next <ArrowForwardOutlinedIcon /></button>
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </section> */}

        </div>
    )
}
