import React from "react";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import './FooterComponent.css'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { ReadMoreButton } from "../ExtraComponents/ReadMoreButton";
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Twitter } from "@mui/icons-material";
import { Link } from "react-router-dom";


export const FooterComponent = () => {
    return (
        <div className="footer-main">
            <Container>
                <Row className="align-items-center justify-content-center ">
                    <Col lg={12}>
                        <Row className=" align-items-center justify-content-center py-3 mt-3 text-start">
                            {/* <Col lg={7}>
                                <div className="py-4  text-left">
                                    <img src={require('../Assets/Footer/image.png')} className="footer-logo" alt="Logo" />
                                </div>
                            </Col> */}
                        </Row>

                        <Row>
                            <Col lg={4}>
                                <div className="head-content text-start">
                                    <img src={require('../Assets/Footer/image.png')} className="footer-logo my-4" alt="Logo" loading='lazy'/>
                                    <h2>
                                        Are You Prepared to Collaborate with us?
                                    </h2>
                                </div>
                                <div className="footer-mail py-3">
                                    <MailOutlineIcon className="email-icon" />
                                    promotions@piloggroup.org
                                </div>
                            </Col>
                            <Col lg={3} className="mt-2 text-start">
                                <div className="our-services-footer ">
                                    <h5>
                                        Our Services
                                    </h5>
                                    <Link to='lean-data-governance'><p className="my-2 text-light">
                                        Lean Data Governance
                                    </p></Link>
                                    <Link to='iDQM'><p className="my-2 text-light"> Data Quality Management</p></Link>
                                    <Link to='ai-lens'><p className="my-2 text-light"> AI Lens</p></Link>
                                    <Link to='strategic-advisory'><p className="my-2 text-light">
                                    Strategic Advisory
                                    </p></Link>

                                    <Link to='value-proposition'><p className="my-2 text-light">
                                        Value Propositions
                                    </p></Link>

                                </div>

                            </Col>
                            <Col lg={3} className="mt-2 text-start">
                                <div className="our-services-footer">
                                    <h5>
                                    Quick Links
                                    </h5>
                                    {/* <p className="my-2">
                                        Publications
                                    </p> */}
                                    <Link to='Contact'><p className="my-2 text-light">
                                        Contact Us
                                    </p></Link>
                                    <Link to='privacy-policy'><p className="my-2 text-light">
                                        Privacy Policy
                                    </p></Link>
                                </div>

                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6} className="mt-5 px-3">
                                <img src={require('../Assets/Footer/customer-first-gartner-blue.png')} alt="gartner-image" className="w-75 " loading='lazy'/>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                           
                            <Col lg={6}>
                            <div className="social-media-icons">
                                    <p> follow us On :</p>
                                    
                                    <div className='footer-social-icons'>
                                        <Link to ="https://www.facebook.com/piloggroup"><img  src={require("../Assets/Contact/FB.png")} alt="FB" loading='lazy'/></Link>
                                        <Link to ="https://x.com/PiLog_group"><img  src={require("../Assets/Contact/Twitter.png")} alt="Twitter" loading='lazy'/></Link>
                                        <Link to ="hhttps://www.youtube.com/channel/UCEOznx22QvZvTbkeJ-lj7GQ"><img  src={require("../Assets/Contact/YouTube.png")} alt="YouTube" loading='lazy'/></Link>
                                        <Link to ="https://www.instagram.com/piloggroup/"><img  src={require("../Assets/Contact/instagram.png")} alt="instagram" loading='lazy'/></Link>
                                        <Link to ="https://www.linkedin.com/company/piloggroup/"><img  src={require("../Assets/Contact/Linkedin.png")} alt="Linkedin" loading='lazy'/></Link>
                                    </div>
                                       
                                 
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex justify-content-center align-items-center">
                                <p>© 2024 PiLog Group. All Rights Reserved</p>
                            </Col>
                            {/* <Col lg={4} className="d-flex justify-content-center align-items-center">
                                <p>
                                    Privacy Policy
                                </p>
                            </Col> */}
                        </Row>
                    </Col>
                </Row>


            </Container>
        </div>
    )
}
