import React from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ReadMoreButton } from '../ExtraComponents/ReadMoreButton'

export const LeanData = () => {
    return (
        <div>
            <section className='ldg-updated' >
                <div>
                    <Row className='d-flex justify-content-center align-items-center text-start'>
                        <Col lg={7}>
                            <div className='ldg-updated-heading'>
                                <h1>Try Our SaaS Platform and Experience
                                    The Power of Lean Data Governance Today!</h1>
                                <p>Introducing PiLog Lean Data Governance:

                                    The Ultimate Subscription-Based Platform for Empowering Businesses</p>
                            </div>
                            {/* <Link to='../subscribe-now'><ReadMoreButton name="Subscribe Now" /></Link> */}
                            <button className='hero-button'>Subscribe Now</button>
                        </Col>
                        <Col lg={5} className='d-flex justify-content-center'>
                            <img className='ldg-updated-banner-side' src={require("../Assets/LDG/LDG-Image.png")} alt="" />
                        </Col>
                    </Row>
                </div>

            </section>
            <section className='ldg-updated-sec-2'>
                <div>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10}>
                            <div className='ldg-updated-sec-2-heading'>
                                <h2>The Lean Data Governance <br /> Concept Promotes Three Significant Pillars.</h2>
                                <p>This conceptual approach is forged into a powerful product suite that has a complete set of tools to serve the event and data driven digital transformation that contemporary enterprises seek.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className='bg-light rounded p-3 my-2 shadow'>
                                <div className='ldg-sec2-card-content shadow '>
                                    <img className='' style={{ width: '50px' }} src={require("../Assets/LDG/LDG-ICONS/Simple, Agile & Speed-07.png")} alt="" loading='lazy' />
                                    <h5 >Simple</h5>
                                </div>
                                <div className='ldg-sec2-card-content2'>

                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p> Preconfigured & Augmented Business & Data Content</p>

                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Shift Left User Engagement Models</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Scalable Solutions & Architecture</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Enables Multi Domain, Tenant & Language</p>


                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='bg-light rounded p-3 my-2 shadow'>
                                <div className='ldg-sec2-card-content shadow'>
                                    <img className='' style={{ width: '50px' }} src={require("../Assets/LDG/LDG-ICONS/Simple, Agile & Speed-08.png")} alt="" loading='lazy' />
                                    <h5 >Agile</h5>
                                </div>
                                <div className='ldg-sec2-card-content2'>

                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p> Preconfigured & Augmented Business & Data Content</p>

                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Shift Left User Engagement Models</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Scalable Solutions & Architecture</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Enables Multi Domain, Tenant & Language</p>


                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='bg-light rounded p-3 my-2 shadow'>
                                <div className='ldg-sec2-card-content shadow'>
                                    <img className='' style={{ width: '50px' }} src={require("../Assets/LDG/LDG-ICONS/Simple, Agile & Speed-09.png")} alt="" loading='lazy' />
                                    <h5 >Swift</h5>
                                </div>
                                <div className='ldg-sec2-card-content2'>

                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p> Preconfigured & Augmented Business & Data Content</p>

                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Shift Left User Engagement Models</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Scalable Solutions & Architecture</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        {/* <i class="fa fa-check" style={{ color: '#AEE1FF' }} aria-hidden="true"></i> */}
                                        <p style={{ color: 'rgb(35 161 235)' }}>✔</p>
                                        <p>Enables Multi Domain, Tenant & Language</p>


                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>

                </div>

            </section>
            <section className='ldg-updated-sec-3'>
                <div>
                    <Row className='d-flex justify-content-center align-items-center gap-2'>
                        <Col lg={5} className='d-flex justify-content-center'>
                            <div className='key-aspect-images'>
                                <img className='image-1' src={require("../Assets/LDG/ldg-key-aspects (1).png")} alt="ldg-key-aspects" loading='lazy' />
                                {/* <img className='image-gif' src={require("../Assets/LDG/cloud_icon.gif")} alt="ldg-key-aspects-cloud" /> */}

                            </div>

                        </Col>
                        <Col lg={6} className='d-flex justify-content-center'>
                            <div className='key-aspect-section'>
                                <div className='empty-div'></div>
                                <div className='key-aspect-section-heading'>
                                    <h2>Here are some key aspects of
                                        Lean Data Governance:</h2>
                                </div>
                                <Row className='my-1 p-3'>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG-new-theme-icons/Here-are-some-key-aspects/img.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Value & Vision (KPIs & KRAs)</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG-new-theme-icons/Here-are-some-key-aspects/img copy 2.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Reusable Content, Repository & Dictionary</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='my-1 p-3'>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG-new-theme-icons/Here-are-some-key-aspects/img_1.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Value & Vision (KPIs & KRAs)</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG-new-theme-icons/Here-are-some-key-aspects/img copy 3.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Reusable Content, Repository & Dictionary</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='my-1 p-3'>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG-new-theme-icons/Here-are-some-key-aspects/img copy.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Value & Vision (KPIs & KRAs)</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG-new-theme-icons/Here-are-some-key-aspects/img copy 4.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Reusable Content, Repository & Dictionary</p>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>

                    </Row>
                </div>
            </section>

            <section className='ldg-updated-sec-4'>
                <div>
                    <div className='impactful-heading'>

                        <h2>Impactful Business Value Management<br />

                            with Lean Data Governance</h2>
                    </div>
                    <Row>
                        <Col lg={3} md={6} xs={12} sm={12}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 5.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Improved Inventory <br /> Visibility</h6>
                                    <p>Improved Inventory Visibility enhances real time tracking and  management of inventory levels.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 6.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Time & Cost Reduction <br />

                                        & Potential Saving</h6>
                                    <p>Cost avoidance by using <br />

                                        non or slow moving items.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 7.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Support Maintenance <br />

                                        & Operations</h6>
                                    <p>During shutdowns.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 8.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Standardized short & <br />

                                        long descriptions</h6>
                                    <p>to improve searching and <br />

                                        easy identification.</p>
                                </div>

                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 9.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Zero Down Time During <br /> Maintenance</h6>
                                    <p>as spares availability <br /> would be HIGH.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 10.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Supplier to Material & <br />Product Category Linkage:</h6>
                                    <p>Easy approach to suppliers in case

                                        the materials found but the owner of

                                        the spare refuse to release.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 11.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Extended Partnership</h6>
                                    <p>Build the foundation & strengthen

                                        the relationship among companies</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div>
                                    <img className='w-25' src={require("../Assets/LDG-new-theme-icons/Impactful Business Value Management  icons/img copy 12.png")} alt="ldg-key-aspects" loading='lazy' />

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Domestic Vs. Import</h6>
                                    <p>Provide accurate information for

                                        local content & decision making</p>
                                </div>

                            </div>
                        </Col>

                    </Row>
                </div>

            </section>

            <section className='ldg-updated-sec-3'>
                <div className='container'>
                    <div className='impactful-heading'>
                        <h2>Lean Data Governance <br />
                            Powered by PiLog iDXP (Data Exchange Platform)</h2>
                    </div>
                    <Carousel>
                        <Carousel.Item>
                            <Row>
                                <Col lg={6} md={12} xs={12}>
                                    <div className='ldg-updated-sec-3-content'>
                                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                                            <img className='w-100 rounded' style={{ height: "278px" }} src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy' />
                                            <div className='d-flex gap-3 m-3 text-center align-items-center'>
                                                <div>
                                                    <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-06.png")} alt="ldg-key-aspects" />
                                                </div>
                                                <div>
                                                    <h5>Data Science</h5>
                                                </div>
                                            </div>
                                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                                data using techniques from statistics, computer science, and
                                                domain expertise. It encompasses data collection, processing,
                                                analysis, and visualization to support decision making and
                                                predictive modeling.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                                        <img className='w-100 rounded' style={{ height: "278px" }} src={require("../Assets/LDG/Generative&Augmented AI.jpg")} alt="data-science" loading='lazy' />
                                        <div className='d-flex gap-3 m-3 text-center align-items-center'>
                                            <div>
                                                <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-07.png")} alt="ldg-key-aspects" />
                                            </div>
                                            <div>
                                                <h5>Generative
                                                    & Augmented AI</h5>
                                            </div>
                                        </div>
                                        <p className='text-start m-3'>Generative AI creates new content or data using machine
                                            learning models, such as text, images, or music. Augmented
                                            AI enhances human capabilities by combining AI s strengths
                                            with human intelligence for improved decision making and
                                            productivity.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row>
                                <Col>
                                    <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                                        <img className='w-100 rounded' style={{ height: "278px" }} src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy' />
                                        <div className='d-flex gap-3 m-3 text-center align-items-center'>
                                            <div>
                                                <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-08.png")} alt="ldg-key-aspects" />
                                            </div>
                                            <div>
                                                <h5>Business & Data KPIs & KRAs</h5>
                                            </div>
                                        </div>
                                        <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                            data using techniques from statistics, computer science, and
                                            domain expertise. It encompasses data collection, processing,
                                            analysis, and visualization to support decision making and
                                            predictive modeling.</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                                        <img className='w-100 rounded' style={{ height: "278px" }} src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy' />
                                        <div className='d-flex gap-3 m-3 text-center align-items-center'>
                                            <div>
                                                <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-09.png")} alt="ldg-key-aspects" />
                                            </div>
                                            <div>
                                                <h5>Industry Standards, Policies, Procedures</h5>
                                            </div>
                                        </div>
                                        <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                            data using techniques from statistics, computer science, and
                                            domain expertise. It encompasses data collection, processing,
                                            analysis, and visualization to support decision making and
                                            predictive modeling.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Row className='d-flex justify-content-center'>
                                <Col lg={6}>
                                    <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                                        <img className='w-100 rounded' style={{ height: "278px" }} src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy' />
                                        <div className='d-flex gap-3 m-3 text-center align-items-center'>
                                            <div>
                                                <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-10.png")} alt="ldg-key-aspects" />
                                            </div>
                                            <div>
                                                <h6>Security, Risk Compliance & Regulations</h6>
                                            </div>
                                        </div>
                                        <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                            data using techniques from statistics, computer science, and
                                            domain expertise. It encompasses data collection, processing,
                                            analysis, and visualization to support decision making and
                                            predictive modeling.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>


            <section className='ldg-updated-sec-6'>
                <div>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={7}>
                            <div  className='impactful-heading text-start'>
                                <h2>The contemporary trend is to be AI Ready,
                                    but are we Data Ready and AI Ready?</h2>
                                <p>
                                    Lean Data Governance is the bedrock for eliminating waste from business
                                    processes and supporting decision making at its best, whether supported
                                    by humans, Artificial Intelligence or both.
                                </p>
                            </div>
                        </Col>
                        <Col lg={5}>
                        <div>
                            <img className='w-75' src={require("../Assets/LDG/IamdSir.png")} alt="IamdSir" />
                        </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}
